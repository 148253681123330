import React, { useState, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Login from './pages/Login/Login';
import Menu from './pages/Menu/Menu';
import RelatorioCusto from './pages/PowerBI/Interno/RelatorioCusto/PowerBI';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    return localStorage.getItem('isLoggedIn') === 'true';
  });

  const handleLogin = (route: string) => {
    setIsLoggedIn(true);
    localStorage.setItem('isLoggedIn', 'true');
  };

  const PowerBIPageLoader = (cityName: string, routeType: 'Prefeitura' | 'Interno' | 'Diversos', requireLogin = true) => {
    const PageComponent = lazy(() => import(`./pages/PowerBI/${routeType}/${cityName}/PowerBI`));
    return (
      <Suspense fallback={<div>Carregando...</div>}>
        {isLoggedIn || !requireLogin ? <PageComponent /> : <Navigate replace to="/login" />}
      </Suspense>
    );
  };

  return (
    <Router>
      <div className="App">
        <main>
          <Routes>
            <Route path="/login" element={<Login onLogin={handleLogin} />} />
            <Route path="/menu" element={<Menu/>} />
            <Route path="/alexania" element={PowerBIPageLoader('Alexania', 'Prefeitura')} />
            <Route path="/amapa" element={PowerBIPageLoader('Amapa', 'Prefeitura')} />
            <Route path="/angra" element={PowerBIPageLoader('Angra', 'Prefeitura')} />
            <Route path="/aparecidagoiania" element={PowerBIPageLoader('AparecidaGoiania', 'Prefeitura')} />
            <Route path="/araguaina" element={PowerBIPageLoader('Araguaina', 'Prefeitura')} />
            <Route path="/gurupi" element={PowerBIPageLoader('Gurupi', 'Prefeitura')} />
            <Route path="/manaus" element={PowerBIPageLoader('Manaus', 'Prefeitura', false)} />
            <Route path="/mariana" element={PowerBIPageLoader('Mariana', 'Prefeitura')} />
            <Route path="/maracanau" element={PowerBIPageLoader('Maracanau', 'Prefeitura')} />
            <Route path="/parauapebas" element={PowerBIPageLoader('Parauapebas', 'Prefeitura', false)} />
            <Route path="/ribeiraoneves" element={PowerBIPageLoader('RibeiraoNeves', 'Prefeitura', false)} />
            <Route path="/riobranco" element={PowerBIPageLoader('RioBranco', 'Prefeitura')} />
            <Route path="/codevasf" element={PowerBIPageLoader('Codevasf', 'Prefeitura', false)} />
            <Route path="/codevasf-producao" element={PowerBIPageLoader('Codevasf_Producao', 'Prefeitura')} />
            <Route path="/vitoriaconquista" element={PowerBIPageLoader('VitoriaConquista', 'Prefeitura', false)} />
            <Route path="/sabara" element={PowerBIPageLoader('Sabara', 'Prefeitura', false)} />
            <Route path="/santoantonio" element={PowerBIPageLoader('Saj', 'Prefeitura', false)} />
            <Route path="/seger" element={PowerBIPageLoader('Seger', 'Prefeitura')} />
            <Route path="/atividades" element={PowerBIPageLoader('Atividades', 'Interno')} />
            <Route path="/aviacao" element={PowerBIPageLoader('Aviacao', 'Interno')} />
            <Route path="/cadastro" element={PowerBIPageLoader('Cadastro', 'Interno')} />
            <Route path="/indicadores-sociais" element={PowerBIPageLoader('IndicadoresSociais', 'Interno')} />
            <Route path="/producao" element={PowerBIPageLoader('Producao', 'Interno')} />
            <Route path="/gerencia-execucao" element={PowerBIPageLoader('GerenciaExecucao', 'Interno')} />
            <Route path="/custo" element={PowerBIPageLoader('Custo', 'Interno')} />
            <Route path="/comparativo-iptu-itbi" element={PowerBIPageLoader('ComparativoIptuItbi', 'Diversos')} />
            <Route path="/relatorio-custo" element={<RelatorioCusto />} />

            <Route path="/" element={<Navigate replace to={isLoggedIn ? '/menu' : '/login'} />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
